import { render, staticRenderFns } from "./LocationDetails.vue?vue&type=template&id=2ce5817c&scoped=true&"
import script from "./LocationDetails.vue?vue&type=script&lang=js&"
export * from "./LocationDetails.vue?vue&type=script&lang=js&"
import style0 from "./LocationDetails.vue?vue&type=style&index=0&id=2ce5817c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ce5817c",
  null
  
)

export default component.exports